.table-responsive {
  margin: 0 0 20px;
}
.table-responsive .table {
  margin: 0;
}
.table th,
.table td {
  text-align: left;
}
.table .col-country{
  min-width: 125px;
}
.table .box-overflow {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table th input {
  max-width: 112px;
}
.table th select {
  max-width: 140px;
}
.text-left {
  text-align: left;
}
.aditinal-info {
  padding-left: 30px;
  overflow: hidden;
}
.aditinal-info dt {
  float: left;
  clear: left;
  margin: 0 5px 0 0;
}
.aditinal-info dd {
  overflow: hidden;
}
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.table .input-group-text {
  padding: 2px 5px;
}
.table .input-group-text svg {
  width: 22px;
  height: 22px;
}
.table .input-holder {
  position: relative;
}
.table .input-holder ul {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #dee2e6;
  padding: 7px 0;
  margin: 3px 0 0;
  list-style: none;
  border-radius: 0.375rem;
  font-weight: normal;
}
.table .input-holder li {
  padding: 3px 10px;
}
.table .input-holder .link:hover {
  background: rgba(0,0,0,0.2);
  cursor: pointer;
}
.btn-delete {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1;
  color: #0d6efd;
  transition: .15s ease-in-out;
  margin-left: -2px;
}
.btn-delete:hover {
  color: #0b5ed7;
}
.btn-delete svg {
  width: 18px;
  height: auto;
}