.upload-file-box {
  margin-bottom: 30px;
}
.upload-file-box input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.upload-file-box input[type="file"]::file-selector-button {
  cursor: pointer;
}
.upload-file-box .upload-file-icon {
  display: block;
  background: url(ico.svg);
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
  margin: 0 auto 1px;
}
.upload-file-box .file-wrap {
  padding: 20px;
  overflow: hidden;
  max-width: 400px;
  width: 100%;
  max-height: 300px;
  height: 100%;
  margin: 0 auto;
  border: 2px dashed #dfdfdf;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
  position: relative;
}
.upload-file-box .max-size {
  font-size: 14px;
  display: block;
}
.upload-file-box .ico {
  width: 25px;
  display: block;
  margin: 0 auto 5px;
}
.upload-file-box .holder {
  overflow: hidden;
  text-align: left;
  width: 290px;
  margin: 0 auto;
  line-height: 24px;
  font-size: 13px;
  padding: 8px 0 0;
}
.upload-file-box .holder img {
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  margin: -5px 20px 0 0;
}
.upload-file-box .inner {
  overflow: hidden;
  position: relative;
}
.upload-file-box .file-size {
  display: inline-block;
  vertical-align: top;
  margin: 1px 0 0 5px;
}
.upload-file-box .file-name {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 65%;
}
.upload-file-box .btn-file-close {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 21px;
  height: 21px;
  border: 2px solid #555;
  border-radius: 50%;
  line-height: 12px;
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  color: #555;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}
.upload-file-box .progress {
  margin: 5px 0 0;
  background-color: #eaeaea;
  height: 10px;
}
.upload-file-box .progress-bar {
  background: #579af6;
}