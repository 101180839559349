.App {
  text-align: center;
  overflow: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content-wrapper {
  padding-top: 100px;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .page-title {
    font-size: 19px;
  }
}


.App .form-label {
  margin-bottom: 3px;
  font-weight: bold;
}
.App .form-control,
.App .form-select {
  padding-top: 3px;
  padding-bottom: 3px;
}
.App .form-text {
  font-size: 13px;
}